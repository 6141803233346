import React from "react"
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Img from "gatsby-image"

import PageTemplate from '../components/pageTemplate'
import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"

import posed, { Transition } from "react-pose";
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';
import styled from "@emotion/styled"

const PoseItem = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    delay: ({ i }) => i * 25,
  },
  exit: {
    opacity: 0,
    x: ({ selectedItemId, id }) =>
      id === selectedItemId ? 50 : -50
  }
});

const AccommodationDetails = styled.div`
  > div:nth-of-type(odd){
    background-color: #F7FAFD;
  }
`

class AccommodationPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    }
  }

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale
    const title = "Transportation Accommodation"

    const post = data.wordpressPage;
    const tabA = post.acf.page_accommodation.tab_a;
    const tabB = post.acf.page_accommodation.tab_b;
    const hasData = (tabA !== null && tabB !== null);

    return (

      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>
        <Crate>
          <MainContainer className="px-5 sm:px-0 pt-5 pb-20">

            {hasData &&
              <div className="text-gray-900">
                <a
                  href={`#tab-a`}
                  className={(this.state.activeTab === 0) ? "block" : "block cursor-pointer"}
                  onClick={() => this.setState({ activeTab: 0 })}>
                  <div className="flex">
                    <p className="flex-grow tk-museo text-mlg text-purple-900">
                      {tabA.title}
                    </p>
                    <span>
                      {this.state.activeTab === 0
                        ? <IoMdArrowDropdown className={`text-pink-900`} style={{ fontSize: `3.5rem` }} />
                        : <IoMdArrowDropup className={`text-pink-900`} style={{ fontSize: `3.5rem` }} />
                      }
                    </span>
                  </div>
                </a>

                <Transition>
                  {(this.state.activeTab === 0) &&
                    <PoseItem key={0}>
                      <span className="block border-t-2 border-pink-900"></span>
                      <div className="text-osm my-10">
                        <div dangerouslySetInnerHTML={{ __html: tabA.content }}></div>
                      </div>
                    </PoseItem>
                  }
                </Transition>

                <span className="block border-t-2 border-pink-900"></span>

                <a
                  href={`#tab-b`}
                  className={(this.state.activeTab === 1) ? "block" : "block cursor-pointer"}
                  onClick={() => this.setState({ activeTab: 1 })}>
                  <div className="flex">
                    <p className="flex-grow tk-museo text-mlg text-purple-900">
                      {tabB.title}
                    </p>
                    <span>
                      {this.state.activeTab === 1
                        ? <IoMdArrowDropdown className={`text-yellow-900`} style={{ fontSize: `3.5rem` }} />
                        : <IoMdArrowDropup className={`text-yellow-900`} style={{ fontSize: `3.5rem` }} />
                      }
                    </span>
                  </div>
                </a>

                <Transition>
                  {(this.state.activeTab === 1) &&
                    <PoseItem key={1}>
                      <span className="block border-t-2 border-yellow-900"></span>
                      <div className="text-osm my-10">
                        <div dangerouslySetInnerHTML={{ __html: tabB.content }}></div>
                        {tabB.accomodation.map((item, key) => {
                          return (
                            <div key={key} className="md:flex -mx-2">
                              <div className="md:w-2/12 px-2 my-8">
                                <Img style={{ maxWidth: `200px` }} fluid={item.image_url.localFile.childImageSharp.fluid} alt="" />
                              </div>
                              <div className="md:w-10/12 px-2 my-8">
                                <div>
                                  <p className="mt-0 mb-2 text-purple-900 text-olg font-bold">{item.title}</p>
                                  <div className="my-1">
                                    <div>
                                      <strong className="text-blue-900"><FormattedMessage id="Address" /></strong>
                                      &nbsp;{item.address}
                                    </div>
                                  </div>
                                  <div className="md:flex mt-1 mb-3 -mx-2">
                                    <div className="md:w-5/12 px-2">
                                      <strong className="text-blue-900"><FormattedMessage id="Email" /></strong>
                                      &nbsp;
                                      <a href={`mailto:${item.email}`}>
                                        {item.email}
                                      </a>
                                    </div>
                                    <div className="md:w-4/12 px-2">
                                      <strong className="text-blue-900"><FormattedMessage id="Web" /></strong>
                                      &nbsp;
                                      <a href={item.website} target="_blank" rel="noopener noreferrer">
                                        {item.website}
                                      </a>
                                    </div>
                                    <div className="md:w-3/12 px-2">
                                      <strong className="text-blue-900"><FormattedMessage id="Phone" /></strong>
                                      &nbsp;{item.phone}
                                    </div>
                                  </div>
                                </div>
                                <AccommodationDetails className="border-t border-b border-yellow-900">

                                  {item.distance_to_venue.length > 0 &&
                                    <div className="md:flex">
                                      <div className="md:flex-1 p-2 text-purple-900" style={{maxWidth: '220px'}}>
                                        <strong>
                                          <FormattedMessage id="Distance to Venue" />
                                        </strong>
                                      </div>
                                      <div className="md:flex-1 p-2">
                                        {item.distance_to_venue}
                                      </div>
                                    </div>
                                  }

                                  {item.room_types.length > 0 &&
                                    <div className="md:flex">
                                      <div className="md:flex-1 p-2 text-purple-900" style={{maxWidth: '220px'}}>
                                        <strong><FormattedMessage id="Room Types" /></strong>
                                      </div>
                                      {item.room_types.map((i, key) => {
                                        return (
                                          <div key={key} className="md:flex-1 p-2">{i.value}</div>
                                        )
                                      })}
                                    </div>
                                  }

                                  {item.room_size.length > 0 &&
                                    <div className="md:flex">
                                      <div className="md:flex-1 p-2 text-purple-900" style={{maxWidth: '220px'}}>
                                        <strong><FormattedMessage id="Room Size" /></strong>
                                      </div>
                                      {item.room_size.map((i, key) => {
                                        return (
                                          <div key={key} className="md:flex-1 p-2">{i.value}m<sup>2</sup></div>
                                        )
                                      })}
                                    </div>
                                  }

                                  {item.number_of_rooms.length > 0 &&
                                    <div className="md:flex">
                                      <div className="md:flex-1 p-2 text-purple-900" style={{maxWidth: '220px'}}>
                                        <strong><FormattedMessage id="Number of Rooms" /></strong>
                                      </div>
                                      {item.number_of_rooms.map((i, key) => {
                                        return (
                                          <div key={key} className="md:flex-1 p-2">{i.value}</div>
                                        )
                                      })}
                                    </div>
                                  }

                                  {item.room_price_per_night.length > 0 &&
                                    <div className="md:flex">
                                      <div className="md:flex-1 p-2 text-purple-900" style={{maxWidth: '220px'}}>
                                        <strong><FormattedMessage id="Room Price Per Night" /></strong>
                                      </div>
                                      {item.room_price_per_night.map((i, key) => {
                                        return (
                                          <div key={key} className="md:flex-1 p-2">{i.value}</div>
                                        )
                                      })}
                                    </div>
                                  }

                                  {item.facilities.length > 0 &&
                                    <div className="md:flex">
                                      <div className="md:flex-1 p-2 text-purple-900" style={{maxWidth: '220px'}}>
                                        <strong><FormattedMessage id="Facilities" /></strong>
                                      </div>
                                      {item.facilities.map((i, key) => {
                                        return (
                                          <div key={key} className="md:flex-1 p-2">{i.value}</div>
                                        )
                                      })}
                                    </div>
                                  }

                                </AccommodationDetails>
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>
                    </PoseItem>
                  }
                </Transition>

                <span className="block border-t-2 border-yellow-900"></span>

              </div>
            }
          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }
}

export default AccommodationPage

export const query = graphql`
  query($pId: Int!, $pType: String!) {
      wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      title
      acf {
        page_accommodation {
          tab_a{
            title
            content
          }
          tab_b{
            title
            content
            accomodation{
              image_url{
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }              
              }
              title
              address
              email
              website
              phone
              distance_to_venue
              room_types{
                value
              }
              room_size{
                value
              }
              number_of_rooms{
                value
              }
              room_price_per_night{
                value
              }
              facilities{
                value
              }
            }
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "jesedu2020-plain-dark.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`
